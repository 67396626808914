const contractInformation = {
  httpStatus: 200,
  response: JSON.stringify({
    data: {
      pageTitle: 'Cheque Especial',
      pageType: 'WORKING',
      components: [
        {
          componentType: 'SINGLE_IMAGE',
          imageUrl: '',
        },
        {
          componentType: 'TITLE_DESCRIPTION',
          title: 'Seu limite de crédito para emergências',
          description:
            'Tenha um limite de crédito liberado diretamente na sua conta para emergências.',
        },
        {
          componentType: 'MONETARY_VALUE',
          title: 'Valor pré-aprovado*',
          value: 'R$ 3.420,00',
          label: '*O valor disponível pode expirar em breve',
        },
        {
          componentType: 'LIST_OF_BENEFITS',
          iconOptions: [
            {
              title: 'Pague apenas se usar o limite, e somente nos dias utilizados.',
              icon: 'moneyEntry',
            },
            {
              title: 'Cancele a qualquer momento, sem multas ou taxas.',
              icon: 'star',
            },
            {
              title: "Flexibilidade para quitar despesas emergenciais'.",
              icon: 'cashback',
            },
          ],
        },
        {
          componentType: 'LIST_OPTIONS',
          title: 'Informações',
          options: [
            {
              title: 'Taxa de juros mensal',
              value: '7,7%',
            },
            {
              title: 'Taxa de juros anual',
              value: '143,55%',
            },
            {
              title: 'IOF',
              value: '0,0082%',
            },
            {
              title: 'IOF Adicional',
              value: '0,38%',
            },
            {
              title: 'CET (custo efetivo total)',
              button: {
                icon: 'help',
                effect: {
                  componentType: 'BOX_MESSAGE',
                  title: 'Custo efetivo total',
                  description:
                    'O calculo do CET(Custo efetivo total) ficará disponível após a contratação da operação de crédito.',
                },
              },
            },
          ],
        },
        {
          componentType: 'CONTRACT_ADHESION',
          title:
            'Ao contratar declaro que li e concordo com as informações descritas no documento de {}.',
          action: 'Termos de adesão',
          actionUrl:
            'https://chqes-uat-documentos.s3.amazonaws.com/app_info/TERMO_DE_ADESAO_V2.pdf?X-Amz-Security-Token=FwoGZXIvYXdzEHwaDOKDjrSl4DXualz5ZSK%2BAbo4b5T6uzu8O9vw1T08g7onw9DC2H7Td5qkvxTCeakdOWKq%2BY7a3%2FpZ72x%2B0oRU%2BkJ%2FPBdXjLe0q6igMQdlKYdklVdnvJkNh1vE0PBheO5z1M7pOxF90EuFExu8loumHGSTiJsHVoyZX%2B%2BhAW8BUFP%2FIcni%2F0N9gBgMtWcoKGgI9Uu8sPF78LQqhSmMiySiCF3nzxSJ%2Fbok59BBUdn7jyl8BQQeQwbWwimvz%2BQuOt9VpQoxkv8CAAmZpr3CbG0orfrMpAYyLWcaH5E5wazfniS010IMcrC2dLtjcR3u28oDO0SneyTnWlln%2BIxnlQGJo0OyoA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230621T185521Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIA4THIYJLEAKPBWHEV%2F20230621%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=07f44d2276cb76672d2354769c851926fd5d853d9c38526459db1305cff57f40',
        },
        {
          componentType: 'BUTTON',
          buttonOptions: [
            {
              type: 'DO_ACTION',
              style: 'primary',
              enable: true,
              text: 'Ativar Gratuitamente',
            },
          ],
        },
      ],
    },
  }),
}

export default contractInformation
