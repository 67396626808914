import React, { ReactNode, isValidElement } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'

import { IconList } from '../icon-list'
import * as S from './styles'

interface MessageStatusProps {
  iconName?: IconListkey
  type?: TypeStatus
  title?: string
  subtitle?: string | ReactNode
}

export const MessageStatus = ({ iconName, type, title, subtitle }: MessageStatusProps) => (
  <S.Container>
    <S.ContainerIcon type={type}>
      {iconName && <IconList name={iconName} color="var(--neutral-theme)" iconSize={32} />}
    </S.ContainerIcon>

    {title && (
      <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
        {title}
      </Text>
    )}

    {subtitle && (
      <>
        <Spacing mb="sm" />
        {isValidElement(subtitle) ? (
          subtitle
        ) : (
          <Text colorWeight={400} variant="body-3">
            {subtitle}
          </Text>
        )}
      </>
    )}
  </S.Container>
)
