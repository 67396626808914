const OverdraftDetails = {
  data: {
    pageTitle: 'Cheque Especial',
    components: [
      {
        componentType: 'BOX_HIDDEN',
        boxOptions: [
          {
            title: 'Saldo em Conta',
            value: 'R$ 203.905.278,75',
          },
          {
            title: 'Saldo+Cheque Especial',
            value: 'R$ 203.905.678,75',
          },
        ],
      },
      {
        componentType: 'LIST_OPTIONS',
        title: 'Limite de Cheque Especial',
        options: [
          {
            title: 'Limite contratado ',
            value: 'R$ 200,00',
          },
        ],
      },
      {
        componentType: 'CONDITIONAL_BOX',
        icon: 'hand',
        title: 'Aumente seu limite para R$ 1.250,00',
        description: 'Clique aqui para aumentar seu limite',
        showBottomsheetOnStartup: false,
        bottomsheet: [
          {
            componentType: 'SINGLE_ICON',
            icon: 'hand',
            status: 'DEFAULT',
          },
          {
            componentType: 'LIST_OPTIONS',
            title: 'Novo limite pré-aprovado de \nR$ 1.250,00',
            description:
              'Seu Cheque Especial tem um novo limite. Aceite o termo de adesão para começar a contar com mais esta vantagem!',
          },
          {
            componentType: 'CONTRACT_ADHESION',
            title: 'Li e concordo com os {} do cheque especial.',
            action: 'Termo de adesão.',
            actionUrl:
              'https://bkt-prd-projetos.s3.amazonaws.com/open_finance/termos/TRANSMISSORA_POLITICA_DE_PRIVACIDADE.pdf',
          },
        ],
      },
      {
        componentType: 'PROGRESS_BAR_WITH_DETAILS',
        details: [
          {
            leftTitle: 'Utilizado',
            leftValue: 'R$ 0,00',
            rightTitle: 'Disponível',
            rightValue: 'R$ 200,00',
            progressPercentage: '50.55%',
          },
        ],
      },
      {
        componentType: 'LIST_OPTIONS',
        title: 'Cobrança pelo uso do limite',
        options: [
          {
            title: 'Dias de uso',
            value: '2',
          },
          {
            title: 'Juros',
            value: 'R$ 2,46',
          },
          {
            title: 'IOF',
            value: 'R$ 1,03',
          },
          {
            title: 'IOF Adicional',
            value: 'R$ 0,05',
          },
          {
            title: 'Valores referentes até 31/08/2022',
          },
        ],
      },
      {
        componentType: 'WARNING',
        title: 'É importante saber',
        description:
          'O valor referente ao uso do Cheque Especial será proporcional ao tempo de utilização e cobrado no 1º dia útil do mês seguinte.',
      },
      {
        componentType: 'LIST_OPTIONS',
        title: 'Taxas e juros',
        options: [
          {
            title: 'Taxa de juros mensal',
            value: '7,77%',
          },
          {
            title: 'Taxa de juros anual',
            value: '92,4%',
          },
          {
            title: 'IOF',
            value: '0,62%',
          },
          {
            title: 'IOF adicional',
            value: '0,00137%',
          },
          {
            title: 'CET (custo efetivo total) mensal',
            value: '8,32%',
          },
          {
            title: 'CET (custo efetivo total) anual',
            value: '99,32%',
          },
        ],
      },
      {
        componentType: 'ACTION',
        title: 'Ações',
        actionOptions: [
          {
            title: 'Termo de adesão',
            icon: 'download',
            type: 'DOWNLOAD_TERMS',
            actionUrl:
              'https://bkt-prd-projetos.s3.amazonaws.com/open_finance/termos/TRANSMISSORA_POLITICA_DE_PRIVACIDADE.pdf',
          },
          {
            title: 'Baixar descritivo de crédito',
            icon: 'download',
            type: 'DOWNLOAD_CREDIT',
          },
          {
            title: 'Cancelar',
            icon: 'canceled',
            type: 'REDIRECT_CANCEL',
          },
        ],
      },
    ],
  },
}
const OverdraftDetailsNewContract = {
  data: {
    pageTitle: 'Cheque Especial',
    components: [
      {
        componentType: 'MESSAGE_STATUS',
        icon: 'checkCircle',
        type: 'SUCCESS',
        title: 'Contrato já assinado',
        description:
          'Estamos processando seu contrato de Cheque Especial e seu limite será liberado em até 24 horas.',
      },
    ],
  },
}

export { OverdraftDetails, OverdraftDetailsNewContract }
