import React, { useEffect, useState, ChangeEvent } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { InputRange } from '@interco/inter-ui/components/InputRange'
import { BoxInfo, BoxSimple, Header, MessageStatus, Skeleton } from '@/components'
import { ApiService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import Ellipse from '@interco/icons/bidis/Icons/Ellipse 11'
import { formatBRLToNumber, formatNumberToMoney } from '@interco/lib-util'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'

import * as S from './styles'

interface IRenderChangeLimitProps {
  limitMoney: string
  limiteDisponivel: string
  limiteUtilizado: string
  setLimitMoney: (value: string) => void
  setEnableNewLimit: (value: boolean) => void
}
const RenderChangeLimit = ({
  limitMoney,
  limiteDisponivel,
  limiteUtilizado,
  setLimitMoney,
  setEnableNewLimit,
}: IRenderChangeLimitProps) => {
  const [warning, setWarning] = useState<string>('')
  const limiteUtilizadoFormat = formatBRLToNumber(limiteUtilizado)
  const limiteDisponivelFormat = formatBRLToNumber(limiteDisponivel)
  const limitTotal = limiteUtilizadoFormat + limiteDisponivelFormat

  const onRenderMsgWhenChangeLimit = (newLimit: number) => {
    let msg = ''
    if (newLimit > limitTotal) {
      msg = 'O valor não pode ser maior que o limite liberado.'
    } else if (newLimit < limiteUtilizadoFormat) {
      msg = 'O valor não pode ser menor que o limite já utilizado.'
    } else {
      const restMoneyToLimit = limitTotal - newLimit
      msg = `Valor disponível para uso: ${formatNumberToMoney('R$', restMoneyToLimit)}`
    }

    setWarning(msg)
  }

  return (
    <S.ContentChangeLimit>
      <S.ContentChangeLimitValue>
        <Text variant="body-2">Ajuste o valor do seu limite</Text>

        <S.InputMoney
          type="currency"
          value={limitMoney}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            const valueToNumber = formatBRLToNumber(value)

            onRenderMsgWhenChangeLimit(valueToNumber)
            setLimitMoney(value)

            if (valueToNumber > limiteUtilizadoFormat && valueToNumber <= limitTotal) {
              setEnableNewLimit(true)
            } else if (valueToNumber > limitTotal) {
              setEnableNewLimit(false)
            } else {
              setEnableNewLimit(false)
            }
          }}
        />
        <S.ContainerInfoUseLimit>
          <Text variant="caption-1">{warning}</Text>
        </S.ContainerInfoUseLimit>
      </S.ContentChangeLimitValue>

      <Spacing mb="xs" />

      <S.ContentChangeLimitRange>
        <InputRange
          min={0}
          max={limitTotal}
          value={formatBRLToNumber(limitMoney)}
          onChange={(newValue: number) => {
            onRenderMsgWhenChangeLimit(newValue)

            if (newValue > limiteUtilizadoFormat) {
              setLimitMoney(formatNumberToMoney('R$', newValue))
              setEnableNewLimit(true)
            } else {
              setLimitMoney(limiteUtilizado)
              setEnableNewLimit(false)
            }
          }}
        />
        <Spacing mb="sm" />
        <div>
          <Text variant="caption-1">R$ 0,00</Text>
          <Text variant="caption-1">{formatNumberToMoney('R$', limitTotal)}</Text>
        </div>
      </S.ContentChangeLimitRange>

      <Spacing mb="xs" />

      <S.Row>
        <Ellipse height={10} color="var(--primary500)" />
        <Text variant="body-3">
          Limite utilizado:{' '}
          <Text variant="body-3" colorWeight={500} bold>
            {limiteUtilizado}
          </Text>
        </Text>
      </S.Row>

      <Spacing mb="xs" />

      <S.Row>
        <Ellipse height={10} color="var(--gray200)" />
        <Text variant="body-3">
          Limite disponível:{' '}
          <Text variant="body-3" colorWeight={500} bold>
            {limiteDisponivel}
          </Text>
        </Text>
      </S.Row>

      <Spacing mb="sm" />
    </S.ContentChangeLimit>
  )
}

export const SkeletonLoading = () => {
  const history = useHistory()

  return (
    <Skeleton.ContainerSkeleton>
      <Header onBackClick={() => history.goBack()}>
        <></>
      </Header>
      <Spacing mb="sm" />

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>
      <Spacing mb="xs" />

      <Skeleton.SkeletonItem newHeight={100} isWidthFull />

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={90} />
          <Spacing mr="xs" />
          <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={10} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={16} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonItem newHeight={40} isWidthFull />
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
          <Spacing mr="xs" />
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
          <Spacing mr="xs" />
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
          <Spacing mr="xs" />
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
          <Spacing mr="xs" />
          <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>
    </Skeleton.ContainerSkeleton>
  )
}

export const OverdraftChangeLimits = () => {
  const history = useHistory()
  const [{ user, appType }] = useGlobalState()
  const [limitMoney, setLimitMoney] = useState<string>('')
  const [enableNewLimit, setEnableNewLimit] = useState<boolean>(false)
  const [showBottomSheetSetNewLimit, setShowBottomSheetSetNewLimit] = useState<boolean>(false)
  const { data, isFetching } = useQuery<OverdraftChangeLimits | undefined>(
    'getOverdraftChangeLimits',
    () =>
      ApiService.OverdraftLimits.getOverdraftChangeLimits({
        account: user?.account as string,
        appType,
      }),
    { retry: false, refetchOnWindowFocus: false },
  )

  useEffect(() => {
    if (!data) return
    setLimitMoney(data.data.limiteUtilizado)
  }, [data])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  if (isFetching) return <SkeletonLoading />

  if (!data) return <></>

  const { pageTitle, limiteDisponivel, limiteUtilizado, components } = data.data
  const boxOptions = components.find((comp) => comp.componentType === 'BOX_SIMPLE')?.boxOptions
  const warning = components.find((comp) => comp.componentType === 'WARNING')

  return (
    <S.Container>
      <Header onBackClick={() => history.goBack()}>{pageTitle}</Header>

      <S.Content>
        <Spacing mb="sm" />

        <BoxSimple options={boxOptions || []} />
        <Spacing mb="sm" />

        <RenderChangeLimit
          limitMoney={limitMoney}
          limiteDisponivel={limiteDisponivel}
          limiteUtilizado={limiteUtilizado}
          setLimitMoney={setLimitMoney}
          setEnableNewLimit={setEnableNewLimit}
        />
        <Spacing mb="sm" />

        <BoxInfo title={warning?.title} description={warning?.description} />
        <Spacing mb="sm" />
      </S.Content>

      <S.Footer>
        <Button
          fullWidth
          onClick={() => setShowBottomSheetSetNewLimit(true)}
          disabled={!enableNewLimit}
        >
          Ajustar limite
        </Button>
      </S.Footer>

      {showBottomSheetSetNewLimit && (
        <BottomSheet onClose={() => setShowBottomSheetSetNewLimit(false)}>
          <MessageStatus
            type="SUCCESS"
            iconName="checkCircle"
            title="Ajuste realizado"
            subtitle="O ajuste do valor do seu limite foi realizado com sucesso."
          />

          <Spacing mb="md" />
          <S.BottomSheetSetNewLimit>
            <Button fullWidth onClick={() => setShowBottomSheetSetNewLimit(false)}>
              Entendi
            </Button>
          </S.BottomSheetSetNewLimit>
        </BottomSheet>
      )}
    </S.Container>
  )
}
