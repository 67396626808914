import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const { innerHeight } = window

export const Container = styled.div`
  ${headerStyles({})}

  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  min-height: ${`${innerHeight}px`};
  background-color: var(--neutral-theme);
`

interface ContentProps {
  showPaddingBottom?: boolean
}
export const Content = styled.div<ContentProps>`
  flex: 1;
  flex-direction: column;
  display: flex;
  padding-bottom: ${({ showPaddingBottom }) => (showPaddingBottom ? '98px' : '24px')};
`

export const ContentSuccessIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ContentBtn = styled.div`
  display: flex;
  width: 100%;
`

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed var(--gray200);
  padding-bottom: 24px;
  text-align: center;
`

export const ContentList = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px 0;
`

export const ContainerFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: block;
  background-color: var(--neutral-theme);
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid var(--gray100);
`
