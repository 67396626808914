/* eslint-disable consistent-return */
import React from 'react'

import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './styles'

interface IBoxInfoProps {
  titlePrimaryButton: string
  titleSecondaryButton?: string
  isFixed?: boolean
  isLoadingPrimaryButton?: boolean
  isLoadingSecondaryButton?: boolean
  disablePrimaryButton?: boolean
  disableSecondaryButton?: boolean
  isShowTopBorder?: boolean
  isFullWidth?: boolean
  variantPrimaryButton?: ButtonOptionsStyle
  variantSecondaryButton?: ButtonOptionsStyle
  onHandleClickPrimaryButton?: () => void
  onHandleClickSecondaryButton?: () => void
}

export const FooterButtons = ({
  titlePrimaryButton,
  titleSecondaryButton,
  isFixed,
  isLoadingPrimaryButton,
  isLoadingSecondaryButton,
  disablePrimaryButton,
  disableSecondaryButton,
  isShowTopBorder,
  isFullWidth,
  variantPrimaryButton = 'primary',
  variantSecondaryButton = 'secondary',
  onHandleClickPrimaryButton,
  onHandleClickSecondaryButton,
}: IBoxInfoProps) => {
  const Buttons = () => (
    <>
      <Button
        fullWidth
        variant={variantPrimaryButton}
        disabled={disablePrimaryButton}
        isLoading={isLoadingPrimaryButton}
        onClick={onHandleClickPrimaryButton}
      >
        {titlePrimaryButton}
      </Button>
      {onHandleClickSecondaryButton && (
        <>
          <Spacing mb="xs" />
          <Button
            fullWidth
            variant={variantSecondaryButton}
            disabled={disableSecondaryButton}
            isLoading={isLoadingSecondaryButton}
            onClick={onHandleClickSecondaryButton}
          >
            {titleSecondaryButton}
          </Button>
        </>
      )}
    </>
  )

  return (
    <>
      {isFixed ? (
        <S.ContainerFixed isShowTopBorder={isShowTopBorder} isFullWidth={isFullWidth}>
          <Buttons />
        </S.ContainerFixed>
      ) : (
        <S.ContainerBlock isShowTopBorder={isShowTopBorder} isFullWidth={isFullWidth}>
          <Buttons />
        </S.ContainerBlock>
      )}
    </>
  )
}
