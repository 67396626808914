import React, { useEffect, useMemo } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { BoxInfo, Header, IconList, ListOptions, MessageStatus } from '@/components'
import { requestAnalytics } from '@/services/bridge'
import { RoutesNames } from '@/routes/routes'
import useGlobalState from '@/contexts/global-state'
import { BridgeService } from '@/services'

import * as S from './styles'

const RenderListOptions = ({
  title,
  options,
}: Pick<ContractInformationComponent, 'title' | 'options'>) => (
  <S.ContentList>
    <ListOptions title={title} options={options || []} />
  </S.ContentList>
)

export const ContractSuccess = () => {
  const [{ appType }] = useGlobalState()
  const history = useHistory()
  const location = useLocation<{
    contractInformationActivated: OverdraftDetails
  }>()

  const dataResponse = location.state.contractInformationActivated.data

  const handleRightClick = () => {
    const response = dataResponse.components.find((item) => item.componentType === 'BUTTON')

    if (response?.buttonOptions?.some((item) => item.type === 'BACK_TO_SUPER_APP')) {
      return () => BridgeService.interWbNavigate.requestGoBack()
    }

    return () => history.push(RoutesNames.OVERDRAFT_DETAILS)
  }

  const hasMessageStatus = useMemo(
    () => dataResponse.components.some((item) => item.componentType === 'MESSAGE_STATUS'),
    [dataResponse.components],
  )

  const hasSingleIcon = useMemo(
    () => dataResponse.components.some((item) => item.componentType === 'SINGLE_ICON'),
    [dataResponse.components],
  )
  const hasButton = useMemo(
    () => dataResponse.components.some((item) => item.componentType === 'BUTTON'),
    [dataResponse.components],
  )

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <S.Container>
      <Header
        onBackClick={() => null}
        hideLeftIcon
        rightIcon="home"
        onRightButtonClick={handleRightClick()}
      >
        {dataResponse.pageTitle}
      </Header>

      <S.Content showPaddingBottom={hasButton}>
        {!hasSingleIcon && !hasMessageStatus && (
          <>
            <Spacing mb="lg" />
            <S.ContentSuccessIcon>
              <IconList name="check" iconSize={80} color="var(--success500)" />
            </S.ContentSuccessIcon>
            <Spacing mb="md" />
          </>
        )}

        {dataResponse.components.map((comp) => {
          if (comp.componentType === 'SINGLE_ICON') {
            return (
              <>
                <Spacing mb="lg" />
                <S.ContentSuccessIcon>
                  <IconList name={comp.icon || 'check'} iconSize={80} color="var(--success500)" />
                </S.ContentSuccessIcon>
                <Spacing mb="md" />
              </>
            )
          }
          if (comp.componentType === 'MESSAGE_STATUS') {
            return (
              <MessageStatus
                iconName={comp.icon}
                type={comp.type}
                title={comp.title}
                subtitle={comp.description}
              />
            )
          }
          if (comp.componentType === 'TITLE_DESCRIPTION') {
            return (
              <S.ContentTitle>
                <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
                  {comp.title}
                </Text>
                {comp.description && (
                  <>
                    <Spacing mt="xs" />
                    <Text color="typography" variant="body-3">
                      {comp.description}
                    </Text>
                  </>
                )}
              </S.ContentTitle>
            )
          }
          if (comp.componentType === 'LIST_OPTIONS') {
            return <RenderListOptions title={comp.title} options={comp.options} />
          }
          if (comp.componentType === 'WARNING') {
            return <BoxInfo title={comp.title} description={comp.description} />
          }
          if (comp.componentType === 'BUTTON') {
            const handleAction = (actionType: ButtonOptionsType) => {
              if (actionType === 'TO_OVERDRAFT_DETAILS') {
                requestAnalytics('Sucesso ao efivar o cheque', {
                  ref_figma: '1',
                  screen: 'Sucesso',
                  content_name: 'Mostrar detalhes do Cheque Especial',
                  content_action: 'clique no botão',
                  content_id: appType || '',
                })
                history.push(RoutesNames.OVERDRAFT_DETAILS)
              }
              if (actionType === 'BACK_TO_SUPER_APP') {
                BridgeService.interWbNavigate.requestGoBack()
              }
            }

            return (
              <S.ContainerFixed>
                {comp.buttonOptions?.map((item, idx) => (
                  <>
                    <Button
                      variant={item.variant}
                      fullWidth
                      onClick={() => handleAction(item.type)}
                    >
                      {item.text}
                    </Button>
                    {idx + 1 < (comp.buttonOptions || [])?.length && <Spacing mb="xxs" />}
                  </>
                ))}
              </S.ContainerFixed>
            )
          }
          return <></>
        })}
      </S.Content>
      {!hasButton && (
        <>
          <S.ContentBtn>
            <Button
              variant="primary"
              onClick={() => {
                requestAnalytics('Sucesso ao efivar o cheque', {
                  ref_figma: '1',
                  screen: 'Sucesso',
                  content_name: 'Mostrar detalhes do Cheque Especial',
                  content_action: 'clique no botão',
                  content_id: appType || '',
                })
                history.push(RoutesNames.OVERDRAFT_DETAILS)
              }}
              fullWidth
            >
              Mostrar detalhes do Cheque Especial
            </Button>
          </S.ContentBtn>
          <Spacing mb="sm" />
        </>
      )}
    </S.Container>
  )
}
