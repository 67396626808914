import React from 'react'

import Help from '@interco/icons/orangeds/LG/help'
import Home from '@interco/icons/orangeds/LG/home'
import Info from '@interco/icons/orangeds/LG/info'
import Hide from '@interco/icons/orangeds/LG/hide'
import Hand from '@interco/icons/bidis/Icons/hand'
import Reuse from '@interco/icons/orangeds/LG/reuse'
import Check from '@interco/icons/bidis/layout/check'
import Dollar from '@interco/icons/orangeds/LG/dollar'
import Search from '@interco/icons/orangeds/LG/search'
import Credit from '@interco/icons/orangeds/LG/credit'
import Pending from '@interco/icons/orangeds/LG/pending'
import Canceled from '@interco/icons/orangeds/LG/canceled'
import Download from '@interco/icons/orangeds/LG/download'
import { ListIconName } from '@/common/constants/listIcons'
import Userdoubt from '@interco/icons/orangeds/SM/userdoubt'
import ArrowLeft from '@interco/icons/orangeds/LG/arrow-left'
import ChevronUp from '@interco/icons/orangeds/LG/chevron-up'
import HEllipsis from '@interco/icons/orangeds/LG/h-ellipsis'
import Calendar from '@interco/icons/bidis/v2/action/calendar'
import ChevronDown from '@interco/icons/orangeds/LG/chevron-down'
import ChevronLeft from '@interco/icons/orangeds/LG/chevron-left'
import CheckCircle from '@interco/icons/orangeds/LG/check-circle'
import Success from '@interco/icons/bidis/v2/action/stats/success'
import ChevronRight from '@interco/icons/orangeds/LG/chevron-right'
import TransferMoney from '@interco/icons/orangeds/LG/transfer-money'
import Relatorios from '@interco/icons/bidis/v2/products/banco/relatorios'

interface IIconListProps {
  name: IconListkey
  iconSize?: number
  color?: string
  onClick?: () => void
}

export const IconList = ({ name, iconSize, color, onClick }: IIconListProps) => {
  const size = iconSize || 24

  const getIcon = () =>
    ({
      [ListIconName.help]: <Help height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.home]: <Home height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.download]: (
        <Download height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.arrowLeft]: (
        <ArrowLeft height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.cancel]: (
        <Canceled height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.canceled]: (
        <Canceled height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.info]: <Info height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.dollar]: <Dollar height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.chevronRight]: (
        <ChevronRight height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.search]: <Search height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.chevronLeft]: (
        <ChevronLeft height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.chevronUp]: (
        <ChevronUp height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.chevronDown]: (
        <ChevronDown height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.transferMoney]: (
        <TransferMoney height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.checkCircle]: (
        <CheckCircle height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.pending]: (
        <Pending height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.credit]: <Credit height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.calendar]: (
        <Calendar height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.userdoubt]: (
        <Userdoubt height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.reuse]: <Reuse height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.hide]: <Hide height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.hEllipsis]: (
        <HEllipsis height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.relatorios]: (
        <Relatorios height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.hand]: <Hand height={size} width={size} onClick={onClick} color={color} />,
      [ListIconName.success]: (
        <Success height={size} width={size} onClick={onClick} color={color} />
      ),
      [ListIconName.check]: <Check height={size} width={size} onClick={onClick} color={color} />,
    }[name])

  return getIcon()
}
